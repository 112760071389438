'use client';

import { useEditor, EditorContent, Editor } from '@tiptap/react';
import { tiptapExtensions } from './TiptapConfigs';
import classNames from 'classnames';
import Placeholder from '@tiptap/extension-placeholder';
import { useEffect } from 'react';
import styles from './tiptapStyles.module.scss';
import CharacterCount from '@tiptap/extension-character-count';
import { twMerge } from 'tailwind-merge';
import HardBreak from '@tiptap/extension-hard-break';

interface ITextEditor {
  content: string;
  renderToolbarProps: (x: Editor) => any;
  onTextUpdate: (z: { text?: string; characterCount?: number }) => void;
  maxLength: number;
  className: string;
  wrapperClassName: string;
  placeholder: string;
  style: any;
  disabled: boolean;
  getCurrentCharacterCount: (x: number) => void;
  _RenderToolbar: any;
  disableNewParaOnNextLine?: boolean;
  keepLastUsedTextTag?: boolean;
  removeHTMLFromPastedString?: boolean;
}

export const TiptapTextEditor = ({
  content = '',
  disabled = false,
  placeholder = 'Type here...',
  style = {},
  className = '',
  wrapperClassName = '',
  renderToolbarProps,
  onTextUpdate = undefined,
  maxLength = undefined,
  _RenderToolbar,
  disableNewParaOnNextLine = false,
  keepLastUsedTextTag = false,
  removeHTMLFromPastedString = true,
}: Partial<ITextEditor>) => {
  const editor = useEditor({
    extensions: [
      ...tiptapExtensions,
      Placeholder.configure({
        placeholder,
        emptyNodeClass:
          'first:before:tw-font-inter first:before:tw-text-gray-400 first:before:tw-float-left first:before:tw-content-[attr(data-placeholder)] first:before:tw-pointer-events-none first:before:tw-h-0 first:before:tw-p-0',
      }),
      CharacterCount.configure({
        limit: maxLength,
      }),
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => {
              const isInList = this.editor.isActive('bulletList') || this.editor.isActive('orderedList');
              if (disableNewParaOnNextLine && !isInList) {
                return this.editor.commands.setHardBreak();
              }
              return false;
            
            }
          };
        },
      }),
    ],
    parseOptions: {
      preserveWhitespace: true,
    },
    editorProps: {
      transformPastedText(text) {
        return text.replace(/\xA0/g, ' ');
      },
      transformPastedHTML(html) {
        if (!removeHTMLFromPastedString) return html;
        return html.replace(/<\/?[^>]+(>|$)/g, ''); // replace(/<\/[^>]+(>|$)/g, '').
      },
    },
    content: typeof content === 'string' ? content : '',
    editable: !disabled,
    onUpdate: ({ editor }) => {
      const text = editor?.getHTML();
      // console.log(
      //   `
      //    keepLastUsedTextTag &&
      //   editor.isActive('heading') &&
      //   (!text || text === '<p></p>')
      // `,
      //   keepLastUsedTextTag,
      //   editor.isActive('heading'),
      //   text
      // );
      if (
        keepLastUsedTextTag &&
        editor.isActive('heading') &&
        (!text || text === '<p></p>')
      ) {
        editor.commands.selectAll();
        editor
          .chain()
          .focus()
          .toggleHeading({ level: editor.getAttributes('heading').level })
          .run();
      }
      onTextUpdate?.({
        text: text === '<p></p>' ? '' : text,
        characterCount: editor.storage.characterCount.characters(),
      });
    },
    onCreate: ({ editor }) => {
      const text = editor?.getHTML();
      onTextUpdate?.({
        text,
        characterCount: editor.storage.characterCount.characters(),
      });
    },
    immediatelyRender: false,
  });

  // Adding extra dependency will unselect the selected text node after editing is done on it
  useEffect(() => {
    // if (disabled)
    if (editor && editor.getHTML() !== content) editor?.commands.setContent(content);
  }, [editor, disabled, content]);

  return (
    <div
      className={classNames(
        !className ? styles.editorContentWrapper : '',
        wrapperClassName
      )}
    >
      <EditorContent
        editor={editor}
        style={style}
        className={twMerge(styles.tiptap, className,)}
        maxLength={maxLength}
      />
      {editor && renderToolbarProps && renderToolbarProps(editor)}
      {editor && _RenderToolbar && <_RenderToolbar editor={editor} />}
    </div>
  );
};
